define("discourse/plugins/swapd-whispers4all/discourse/initializers/user-whispers", ["exports", "discourse/controllers/composer", "discourse/models/composer", "discourse-common/utils/decorators", "discourse-common/lib/icon-library", "@ember/runloop"], function (_exports, _composer, _composer2, _decorators, _iconLibrary, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "user-whispers",
    initialize(container) {
      _composer.default.reopen(dt7948.p({
        canWhisper(whisperer, modelAction, isAdmin) {
          // admin is null whenever there is no post, i.e. when there is no reply, i.e. when it's a new topic
          return this.siteSettings.user_whispers_enabled && modelAction === _composer2.default.REPLY && (whisperer || isAdmin == null || isAdmin) || this._super(...arguments);
        },
        _setDangerClass() {
          if (!this.get("isWhispering")) return this._removeDangerClass();
          if (!this.siteSettings.user_whispers_enabled) return this._removeDangerClass();
          if (!this.get("isStaffUser")) return this._removeDangerClass();

          // if we are replying and the post we're replying to was not made by an admin
          const isAdmin = this.get("model.post.admin");
          if (isAdmin != null && !isAdmin) {
            return this._addDangerClass();
          }
        },
        _addDangerClass() {
          (0, _runloop.next)(this, () => {
            $("#reply-control").addClass("user-whisper-danger");
          });
        },
        _removeDangerClass() {
          (0, _runloop.next)(this, () => {
            $("#reply-control").removeClass("user-whisper-danger");
          });
        },
        _addWhisperIcon() {
          if (this.get("showPreview") && this.get("isWhispering") && $("#reply-control .user-whisper").length < 1) {
            const icon = (0, _iconLibrary.iconHTML)("far-eye-slash", {
              class: "user-whisper"
            });
            (0, _runloop.next)(this, () => {
              $(".d-editor-preview").prepend(icon);
            });
          } else {
            $(".d-editor-preview .user-whisper").remove();
          }
        }
      }, [["method", "canWhisper", [(0, _decorators.default)("whisperer", "model.action", "model.post.admin", "model.post")]], ["method", "_setDangerClass", [(0, _decorators.observes)("isStaffUser", "model.post.admin", "isWhispering", "model.post")]], ["method", "_addWhisperIcon", [(0, _decorators.observes)("showPreview", "isWhispering", "model.post")]]]));
    }
  };
});